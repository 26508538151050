<template>
    <div v-if="type == 'mmt'" style="background-color: white;">
        <table class="table table-bordered table-nowrap table-striped">
            <thead class="table-light">
                <tr>
                    <th rowspan="2" style="text-align: center; ">Sample</th>
                    <th>Wetting Time Top</th>
                    <th>Wetting Time Bottom</th>
                    <th>Top Absorption Rate</th>
                    <th>Bottom Absorption Rate</th>
                    <th>Top Max Wetted Radius</th>
                    <th>Bottom Max Wetted Radius</th>
                    <th>Top Spreading Speed</th>
                    <th>Bottom Spreading Speed</th>
                    <th>Accumulative one-way transport index</th>
                    <th rowspan="2">OMMC</th>
                </tr>
                <tr>
                    <th colspan="2">(sec)</th>
                    <th colspan="2">(%/sec)</th>
                    <th colspan="2">(mm)</th>
                    <th colspan="2">(mm/sec)</th>
                    <th>(%)</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(info, index) in tableData" :key="index">
                    <td>{{ info.sample }}</td>
                    <td>{{ info.wetting_time_top }}</td>
                    <td>{{ info.wetting_time_bottom }}</td>
                    <td>{{ info.top_absorption_rate }}</td>
                    <td>{{ info.bottom_absorption_rate }}</td>
                    <td>{{ info.top_max_wetted_radius }}</td>
                    <td>{{ info.bottom_max_wetted_radius }}</td>
                    <td>{{ info.top_spreading_speed }}</td>
                    <td>{{ info.bottom_spreading_speed }}</td>
                    <td>{{ info.accumulative_one_way_transport_index }}</td>
                    <td>{{ info.ommc }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div v-else style="background-color: white;">
        <table class="table table-bordered table-nowrap table-striped">
            <thead class="table-light">
                <tr>
                    <th rowspan="3" colspan="1">Sample</th>
                    <th colspan="4">Bending</th>
                    <th colspan="5">Compression</th>
                    <th colspan="3">Flux</th>
                    <th colspan="2">Friction</th>
                    <th colspan="4">Roughness</th>
                    <th colspan="4">Primary Sensory Indices</th>
                </tr>
                <tr>
                    <th>BARa</th>
                    <th>BARe</th>
                    <th>BWa</th>
                    <th>BWe</th>
                    <th>T</th>
                    <th>CW</th>
                    <th>CRR</th>
                    <th>CAR</th>
                    <th>RAR</th>
                    <th>TCC</th>
                    <th>TCR</th>
                    <th>Qmax</th>
                    <th>SFCa</th>
                    <th>SFCe</th>
                    <th>SRAa</th>
                    <th>SRAe</th>
                    <th>SRWa</th>
                    <th>SRWe</th>
                    <th>Smoothness</th>
                    <th>Softness</th>
                    <th>Warmth</th>
                    <th>Total</th>
                </tr>
                <tr>
                    <th colspan="4">gf.mm/rad</th>
                    <th colspan="1">mm</th>
                    <th colspan="1">gf*mm</th>
                    <th></th>
                    <th colspan="2">gf/(mm^3)</th>
                    <th colspan="2">W/(m*K)</th>
                    <th>W/(m^2)</th>
                    <th></th>
                    <th></th>
                    <th colspan="4">mm</th>
                    <th colspan="4"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(info, index) in tableData" :key="index">
                    <td>{{ info.sample }}</td>
                    <td>{{ toFixed(info.bending_bara) }}</td>
                    <td>{{ toFixed(info.bending_bare) }}</td>
                    <td>{{ toFixed(info.bending_bwa) }}</td>
                    <td>{{ toFixed(info.bending_bwe) }}</td>
                    <td>{{ toFixed(info.compression_t) }}</td>
                    <td>{{ toFixed(info.compression_cw) }}</td>
                    <td>{{ toFixed(info.compression_crr) }}</td>
                    <td>{{ toFixed(info.compression_car) }}</td>
                    <td>{{ toFixed(info.compression_rar) }}</td>
                    <td>{{ toFixed(info.flux_tcc) }}</td>
                    <td>{{ toFixed(info.flux_tcr) }}</td>
                    <td>{{ toFixed(info.flux_qmax) }}</td>
                    <td>{{ toFixed(info.friction_sfca) }}</td>
                    <td>{{ toFixed(info.roughness_srae) }}</td>
                    <td>{{ toFixed(info.roughness_sraa) }}</td>
                    <td>{{ toFixed(info.roughness_srae) }}</td>
                    <td>{{ toFixed(info.roughness_srwa) }}</td>
                    <td>{{ toFixed(info.roughness_srwe) }}</td>
                    <td>{{ toFixed(info.primary_sensory_indices_smoothness) }}</td>
                    <td>{{ toFixed(info.primary_sensory_indices_softness) }}</td>
                    <td>{{ toFixed(info.primary_sensory_indices_warmth) }}</td>
                    <td>{{ toFixed(info.primary_sensory_indices_total) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import {
        getReport
    } from "@/service/index";
    export default {
        data() {
            return {
                type: null,
                id: null,
                tableData: []
            }
        },
        created() {
            const params = this.$route.params
            this.type = params.type
            this.id = params.id
            this.requestReport()
        },
        methods: {
            requestReport() {
                let params = {
                    type: this.type,
                    // id: '5e611cb0a913592892061f5c',
                    // id: '5eb57203895e2b7ed5c1d3b1',
                    id: this.id,
                }
                getReport(params)
                    .then((res) => {
                        if (res && res.payload) {
                            this.tableData = res.payload
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            },
            toFixed(number) {
                var num = number.toFixed(2)
                return num
            }
        }
    }
</script>

<style scoped lang="scss"></style>
<style>
    .table th,
    .table td {
        text-align: center;
        vertical-align: middle !important;
    }
</style>
